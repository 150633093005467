
import {computed, defineComponent, onMounted, ref,} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {ErrorMessage, Field, Form} from "vee-validate";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import currency from "@/core/data/currency";
import posTypes from "@/core/data/postypes";

interface IFormVals {
    BankID: string;
    Title: string;
    ShortHand: string;
    Cur: string;
    Type: string;
    StoreKey: string;
    TerminalID: string;
    MerchantID: string;
    TerminalProvUserID: string;
    TerminalUserID: string;
}

export default defineComponent({
    name: "bank-pos-addnew",
    components: {
        Field,
        Form,
        ErrorMessage,
    },

    setup(props) {

        onMounted(() => {
            setCurrentPageBreadcrumbs("Poslar", ["Ödeme Yöntemleri"]);
        });

      store.dispatch(Actions.BANKS_LIST, {});
      const dataBanksList = computed(() => {
        console.log(store.getters.banksList)
        return store.getters.banksList;
      });

        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const modalRef = ref<null | HTMLElement>(null);

        const formVals = ref<IFormVals>({
            BankID: "",
            Title: "",
            ShortHand: "",
            Cur: "",
            Type: "",
            StoreKey: "",
            TerminalID: "",
            MerchantID: "",
            TerminalProvUserID: "",
            TerminalUserID: "",
        });

        const validationSchema = Yup.object().shape({
            BankID: Yup.string().required("Banka Adı girilmemiş"),
            Title: Yup.string().required("Pos Adı girilmemiş"),
            ShortHand: Yup.string().required("Pos Kısa Adı girilmemiş"),
            Cur: Yup.string().required("Kur seçilmemiş"),
            Type: Yup.string().required("Durum seçilmemiş"),
        });

        const submit = () => {
            if (!submitButtonRef.value) {
                return;
            }

            const payload1 = {
                BankID: formVals.value.BankID,
                Title: formVals.value.Title,
                ShortHand: formVals.value.ShortHand,
                Cur: formVals.value.Cur,
                Type: formVals.value.Type,
                StoreKey: formVals.value.StoreKey,
                TerminalID: formVals.value.TerminalID,
                MerchantID: formVals.value.MerchantID,
                TerminalProvUserID: formVals.value.TerminalProvUserID,
                TerminalUserID: formVals.value.TerminalUserID,
            }

            console.log("submit v2 "+JSON.stringify(payload1));

            store.dispatch(Actions.BANKPOS_INSERT, payload1)
                .then(() => {
                    store.dispatch(Actions.BANKPOS_LIST, {});
                    Swal.fire({
                        text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Geri Dön!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-primary",
                        },
                    }).then(function () {
                        submitButtonRef.value.disabled = false;
                        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
                        hideModal(modalRef.value);
                    });
                }).catch(() => {
                    Swal.fire({
                        text: store.getters.getBankPosErrors[0],
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Tekrar dene!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-danger",
                        },
                });
                submitButtonRef.value.disabled = false;
                submitButtonRef.value.setAttribute("data-kt-indicator", "off");
            });


        }

        return {
            formVals, validationSchema, submit,
            submitButtonRef, modalRef, currency, dataBanksList, posTypes,
        };
    },

});
